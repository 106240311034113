import type { AlgoliaProduct, AlgoliaCategory } from '~/types/models/algoliaProduct';
import type { SearchResponse } from '@algolia/client-search';

const MAX_SEARCH_LENGTH = 100;
const DEBOUNCE_THRESHOLD = 300;
const PRODUCT_LIMIT = 9;
const CATEGORY_LIMIT = 7;
const MIN_ALLOWED_SEARCH_LENGTH = 3;

export function useLayoutStoreSearch() {
    const error = useState<string | null>('search-error', () => null);
    const active = useState('status-search-bar', () => false);
    const loading = useState('search-results-loading', () => false);
    const typingQuery = useState('typing-query-search-bar', () => '');
    const productSearchResults: Ref<SearchResponse<AlgoliaProduct> | null> = useState(
        'search-results-product',
        () => null,
    );
    const categorySearchResults: Ref<SearchResponse<AlgoliaCategory> | null> = useState(
        'search-results-category',
        () => null,
    );

    const { isLoggedIn } = useUser();
    const { productIndex, categoryIndex, getDefaultFacetFilters } = useAlgolia();
    const { search } = useAlgoliaSearch(productIndex.value);
    const { search: categorySearch } = useAlgoliaSearch(categoryIndex.value);

    const showSuggest = computed(() => active.value && typingQuery.value?.length > 0);
    const searchResults: ComputedRef<AlgoliaProduct[]> = computed(
        () => (productSearchResults.value?.hits as AlgoliaProduct[]) || [],
    );
    const getTotal = computed(() => productSearchResults.value?.nbHits || 0);
    const relatedCategories: ComputedRef<AlgoliaCategory[]> = computed(() => categorySearchResults.value?.hits || []);
    const defaultFacetFilters = ref<string[] | string[][]>([]);
    const productQueryId = computed(() => productSearchResults.value?.queryID || '');
    const categoryQueryId = computed(() => categorySearchResults.value?.queryID || '');

    watch(
        isLoggedIn,
        () => {
            defaultFacetFilters.value = getDefaultFacetFilters({ archive: true, bWare: true });
        },
        { immediate: true },
    );

    const getHitCategoryData = async (query: string = ''): Promise<AlgoliaCategory[]> => {
        try {
            error.value = null;
            const filters = ['excludeFromSearch:false'];
            if (!isLoggedIn.value) {
                filters.push('onlyAuthenticated:false');
            }

            const response = await categorySearch({
                query: query || typingQuery.value,
                requestOptions: {
                    filters: filters.join(' AND '),
                    hitsPerPage: CATEGORY_LIMIT,
                    clickAnalytics: true,
                },
            });

            categorySearchResults.value = response as SearchResponse<AlgoliaCategory>;
            return relatedCategories.value;
        } catch (err) {
            Logger.captureException(err);
            handleError(err);
        }

        return [];
    };

    const shouldCancel = ref(false);
    const cancelSearch = () => {
        shouldCancel.value = true;
    };

    const performSuggestSearch = useDebounceFn(async () => {
        if (typingQuery.value?.length > MAX_SEARCH_LENGTH) {
            typingQuery.value = typingQuery.value.slice(0, MAX_SEARCH_LENGTH);
        }

        try {
            if (shouldCancel.value) {
                shouldCancel.value = false; // reset flag once cancelled
                return;
            }

            if (!typingQuery.value || typingQuery.value?.length < MIN_ALLOWED_SEARCH_LENGTH) {
                // reset search results
                productSearchResults.value = null;
                return;
            }

            error.value = null;
            // in some cases the search popover is not open yet
            active.value = true;
            loading.value = true;

            const [response] = await Promise.all([
                search({
                    query: typingQuery.value,
                    requestOptions: {
                        hitsPerPage: PRODUCT_LIMIT,
                        facetFilters: defaultFacetFilters.value,
                        clickAnalytics: true,
                    },
                }),
                getHitCategoryData(),
            ]);

            productSearchResults.value = response as SearchResponse<AlgoliaProduct>;
        } catch (err) {
            Logger.captureException(err);
            handleError(err);
        }

        loading.value = false;
    }, DEBOUNCE_THRESHOLD);

    const handleError = (err: unknown) => {
        error.value = 'errors.message-default';
        if (err instanceof Error || err instanceof Object) {
            // @ts-ignore
            const errorMessage = err?.message;
            if (isBlockedByBrowserError(errorMessage)) {
                error.value = 'errors.blockedByClient';
            }
        }
    };

    const resetSearch = () => {
        productSearchResults.value = null;
        categorySearchResults.value = null;
        typingQuery.value = '';
    };

    return {
        typingQuery,
        error,
        active,
        showSuggest,
        searchResults,
        loading,
        getTotal,
        performSuggestSearch,
        relatedCategories,
        productQueryId,
        categoryQueryId,
        cancelSearch,
        resetSearch,
        getHitCategoryData,
        MIN_ALLOWED_SEARCH_LENGTH,
    };
}
